import type * as Stitches from '@stitches/react'

import { css } from '../stitches.config'

export const sharedTypography = css({
  variants: {
    variant: {
      h1: {
        textStyle: '$h1',
      },
      h2: {
        textStyle: '$h2',
      },
      h3: {
        textStyle: '$h3',
      },
      h4: {
        textStyle: '$h4',
      },
      h5: {
        textStyle: '$h5',
      },
      h6: {
        textStyle: '$h6',
      },
      subtitle1: {
        textStyle: '$subtitle1',
      },
      subtitle2: {
        textStyle: '$subtitle2',
      },
      body1: {
        textStyle: '$body1',
      },
      body2: {
        textStyle: '$body2',
      },
      button: {
        textStyle: '$button',
      },
      caption: {
        textStyle: '$caption',
      },
      overline: {
        textStyle: '$overline',
      },
      blockquote: {
        textStyle: '$blockquote',
      },
    },
  },
  defaultVariants: {
    variant: 'body1',
  },
})

export type sharedTypographyProps = Stitches.VariantProps<typeof sharedTypography>
