import type * as Stitches from '@stitches/react'
import { createStitches, defaultThemeMap } from '@stitches/react'
import { AtRule } from '@stitches/react/global'

import { defaultDarkThemeTokens } from './utils/theme-utils/defaultDarkThemeTokens'
import { defaultLightThemeTokens } from './utils/theme-utils/defaultLightThemeTokens'
import { pubgenDarkThemeTokens } from './utils/theme-utils/pubgenDarkThemeTokens'
import { pubgenLightThemeTokens } from './utils/theme-utils/pubgenLightThemeTokens'

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  prefix: 'pubgen-',
  themeMap: {
    ...defaultThemeMap,
    textTransform: 'fontCases',
    fontStyle: 'fontStyles',
    marginBottom: 'spacesAfter',
  },
  theme: defaultLightThemeTokens,
  media: {
    // why media queries expressed in em https://zellwk.com/blog/media-query-units/
    bp1: '(min-width: 37.5em)', //600px ==> sm or small
    bp2: '(min-width: 56.5625em)', // 905px ==> md or medium
    bp3: '(min-width: 77.5em)', // 1240px ==> lg or large
    bp4: '(min-width: 87.5em)', // 1400px ==> xl or x large
    rbpInitial_1: '(0em <= width < 37.5em)',
    rbp1_2: '(37.5em <= width < 56.5625em)',
    rbp2_3: '(56.5625em <= width < 77.5em)',
    rbp3_4: '(77.5em <= width < 87.5em)',
    'bp1.5': '(min-width: 46.875em)', // 750px ==> tablet. Special case for tablet. 15 is conceptually 1.5
    'rbpInitial_1.5': '(0em <= width < 46.875em)', // 750px ==> tablet. Special case for tablet. 15 is conceptually 1.5
    'rbp1_1.5': '(37.5em <= width < 46.875em)',
    'rbp1.5_2': '(46.875em <= width < 56.5625em)',
  },
  utils: {
    textStyle: (fontCase: Stitches.ScaleValue<'fontCases'>) => ({
      fontFamily: fontCase,
      fontWeight: fontCase,
      fontStyle: fontCase,
      textTransform: fontCase,
      fontSize: fontCase,
      lineHeight: fontCase,
      letterSpacing: fontCase,
    }),
    p: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.ScaleValue<'space'>) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.ScaleValue<'space'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    m: (value: Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (value: Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.ScaleValue<'space'>) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.ScaleValue<'space'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.ScaleValue<'space'>) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.ScaleValue<'space'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
  },
})

export type CSS = Stitches.CSS<typeof config>

export const globalCssObject = {
  ':root': {
    fontSize: '53.125%', // 0.85% 1rem at 10px
    '@bp1': {
      fontSize: '62.5%', // 1rem = 10px
    },
  },
  '*': { margin: 0, padding: 0 },
  html: { height: '100%' },
  '#root': { height: '100%' },
  body: {
    height: '100%',
    webkitFontSmooting: 'antialised',
    mosOsxFontSmoothing: 'grayscale',
    counterReset: 'page',
    fontSmoothing: 'antialiased',
    textRendering: 'optimizeLegibility',
    fontSmooth: 'always',
    '#nprogress': {
      '.bar': {
        backgroundColor: '$pri',
      },
      '.peg': {
        boxShadow: '0 0 10px $color$pri, 0 0 5px $colors$pri',
      },
    },
  },
  a: {
    color: 'none',
    textDecoration: 'none',
  },
}

export const globalStyles = (fontFaceInput: AtRule.FontFace[]) =>
  globalCss({
    '@font-face': fontFaceInput,
    ...globalCssObject,
  })

export const pubgenDarkTheme = createTheme('pubgen-dark', pubgenDarkThemeTokens)
export const pubgenLightTheme = createTheme('pubgen-light', pubgenLightThemeTokens)
export const defaultLightTheme = createTheme('publication-light', defaultLightThemeTokens)
export const defaultDarkTheme = createTheme('publication-dark', defaultDarkThemeTokens)
