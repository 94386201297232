import { THEME_TOKENS } from '@models/theme-types'

import { systemTokens } from './systemTokens'

export const defaultDarkThemeTokens = {
  ...systemTokens,
  colors: {
    none: 'transparent',
    // Primary
    pri: 'rgba(64,121,162,1)', // secondary (ali)
    priText: '$gs1',
    pri_90: 'rgba(64,121,162,0.90)',
    pri_L: 'rgba(84,141,182,1)',
    pri_D: 'rgba(44,101,142,1)',
    // Secondary
    sec: 'rgba(243,99,3,1)', // primary (ben)
    secText: '$gs12',
    sec_90: 'rgba(243,99,3,0.90)',
    sec_L: 'rgba(255,119,23,1)',
    sec_D: 'rgba(223,79,0,1)',
    // Link
    lin: 'rgba(74,180,249,1)', // link (han)
    linText: '$gs12',
    lin_90: 'rgba(74,180,249,0.90)',
    lin_L: 'rgba(94,200,255,1)',
    lin_D: 'rgba(54,160,229,1)',
    // Alert
    ale: 'rgba(179,58,58,1)', // ale (ivy)
    aleText: '$gs1',
    ale_90: 'rgba(179,58,58,0.90)',
    ale_L: 'rgba(199,78,78,1)',
    ale_D: 'rgba(159,38,38,1)',
    // Success
    suc: 'rgba(56, 142, 60,1)', // success (jim)
    sucText: '$gs1',
    suc_90: 'rgba(56, 142, 60,0.90)',
    suc_L: 'rgb(65, 164, 69,1)',
    suc_D: 'rgba(50,127,53,1)',
    // Warning
    war: 'rgba(214,191,115,1)', // success (new color)
    war_90: 'rgba(214,191,115,0.90)',
    warText: '$gs12',
    war_L: 'rgba(234,211,135,1)',
    war_D: 'rgba(195,171,95,1)',
    // Gray Scales
    gs1: '#161616',
    gs2: '#1c1c1c',
    gs3: '#232323',
    gs4: '#282828',
    gs5: '#2e2e2e',
    gs6: '#343434',
    gs7: '#3e3e3e',
    gs8: '#505050',
    gs9: '#707070',
    gs10: '#7e7e7e',
    gs11: '#a0a0a0',
    gs12: '#ededed',
    b1: 'rgba(255,255,255,1)',
    b2: 'rgba(255,255,255,0.9167)',
    b3: 'rgba(255,255,255,0.8334)',
    b4: 'rgba(255,255,255,0.6668)',
    b5: 'rgba(255,255,255,0.5835)',
    b6: 'rgba(255,255,255,0.5002)',
    b7: 'rgba(255,255,255,0.4169)',
    b8: 'rgba(255,255,255,0.3336)',
    b9: 'rgba(255,255,255,0.2503)',
    b10: 'rgba(255,255,255,0.167)',
    b11: 'rgba(255,255,255,0.0837)',
    b12: 'rgba(255,255,255,0.0004)',
    w1: 'rgba(0,0,0,0.916)',
    w2: 'rgba(0,0,0,0.833)',
    w3: 'rgba(0,0,0,0.750)',
    w4: 'rgba(0,0,0,0.666)',
    w5: 'rgba(0,0,0,0.583)',
    w6: 'rgba(0,0,0,0.50)',
    w7: 'rgba(0,0,0,0.416)',
    w8: 'rgba(0,0,0,0.333)',
    w9: 'rgba(0,0,0,0.250)',
    w10: 'rgba(0,0,0,0.167)',
    w11: 'rgba(0,0,0,0.083)',
    w12: 'rgba(0,0,0,0.000)',
    // Tokens for ui elements used in our system that users can customize.
    btnPriBg: '$pri',
    btnPriBo: '$priText',
    btnPriText: '$priText',
    btnPriBg_L: '$pri_L',
    btnPriBg_D: '$pri_D',
    btnSecBg: '$sec',
    btnSecBo: '$secText',
    btnSecText: '$secText',
    btnSecBg_L: '$sec_L',
    btnSecBg_D: '$sec_D',
    btnTerBg: '$gs3',
    btnTerBo: '$gs12',
    btnTerText: '$gs12',
    btnTerBg_L: '$gs4',
    btnTerBg_D: '$gs5',
    btnGhoBg: '$none',
    btnGhoBo: '$pri',
    btnGhoText: '$pri',
    btnGhoBg_L: '$gs4',
    btnGhoBg_D: '$gs5',
  },
  fonts: {
    h1: 'var(--font-family-neuton), serif',
    h2: 'var(--font-family-neuton), serif',
    h3: 'var(--font-family-neuton), serif',
    h4: 'var(--font-family-neuton), serif',
    h5: 'var(--font-family-neuton), serif',
    h6: 'var(--font-family-roboto), sans-serif',
    subtitle1: 'var(--font-family-neuton), serif',
    subtitle2: 'var(--font-family-roboto), sans-serif',
    body1: 'var(--font-family-roboto), sans-serif',
    body2: 'var(--font-family-roboto), sans-serif',
    button: 'var(--font-family-roboto), sans-serif',
    caption: 'var(--font-family-neuton), serif',
    overline: 'var(--font-family-roboto), sans-serif',
    blockquote: 'var(--font-family-roboto), sans-serif',
  },
  fontWeights: {
    h1: '400',
    h2: '400',
    h3: '400',
    h4: '400',
    h5: '400',
    h6: '400',
    subtitle1: '400',
    subtitle2: '400',
    body1: '400',
    body2: '400',
    button: '400',
    caption: '400',
    overline: '500',
    blockquote: '400',
  },
  fontStyles: {
    h1: 'normal',
    h2: 'normal',
    h3: 'normal',
    h4: 'normal',
    h5: 'normal',
    h6: 'normal',
    subtitle1: 'normal',
    subtitle2: 'normal',
    body1: 'normal',
    body2: 'normal',
    button: 'normal',
    caption: 'normal',
    overline: 'normal',
    blockquote: 'normal',
  },
  fontCases: {
    h1: 'none',
    h2: 'none',
    h3: 'none',
    h4: 'none',
    h5: 'none',
    h6: 'uppercase',
    subtitle1: 'none',
    subtitle2: 'none',
    body1: 'none',
    body2: 'none',
    button: 'uppercase',
    caption: 'none',
    overline: 'uppercase',
    blockquote: 'none',
  },
  fontSizes: {
    // 1rem = 10px
    h1: '5rem',
    h2: '4.8rem',
    h3: '4.2rem',
    h4: '3.5rem',
    h5: '2.8rem',
    h6: '2.7rem',
    subtitle1: '2.4rem',
    subtitle2: '2.0rem',
    body1: '1.7rem',
    body2: '1.5rem',
    button: '1.6rem',
    caption: '1.45rem',
    overline: '1.3rem',
    blockquote: '1.7rem',
  },
  spacesAfter: {
    h1: '0.7em',
    h2: '0.625em',
    h3: '0.54em',
    h4: '0.54em',
    h5: '0.54em',
    h6: '0.7em',
    subtitle1: '0.7em',
    subtitle2: '0.5em',
    body1: '0.76em',
    body2: '0.73em',
    button: '0em',
    caption: '0.55em',
    overline: '0.69em',
    blockquote: '1.29em',
    ...systemTokens.spacesAfter,
  },
  lineHeights: {
    h1: '1.1em',
    h2: '1.2em',
    h3: '1.4em',
    h4: '1.2em',
    h5: '1.4em',
    h6: '1.4em',
    subtitle1: '1.4em',
    subtitle2: '1.4em',
    body1: '1.6em',
    body2: '1.5em',
    button: '1.5em',
    caption: '1.4em',
    overline: '1.4em',
    blockquote: '1.4em',
  },
  letterSpacings: {
    h1: '0em',
    h2: '0em',
    h3: '0em',
    h4: '0em',
    h5: '0em',
    h6: '0.03em',
    subtitle1: '0em',
    subtitle2: '0em',
    body1: '0em',
    body2: '0em',
    button: '0em',
    caption: '0em',
    overline: '0.06em',
    blockquote: '0em',
  },
  borderWidths: {
    btnPri: '0px',
    btnSec: '0px',
    btnTer: '1px',
    btnGho: '0px',
  },
  borderStyles: {
    btnPri: 'solid',
    btnSec: 'solid',
    btnTer: 'solid',
    btnGho: 'solid',
  },
  shadows: {
    bottom: '0 0.125rem 0.125rem -0.0625rem rgba(255, 255, 255, 0.8)',
    around: '0 0 0.3125rem rgba(255,255,255,0.8),0 0.0625rem 0.125rem rgb(255, 255, 255, 0.8)',
    card: '0 0 0.3125rem rgba(255,255,255,0.8),0 0.0625rem 0.125rem rgb(255, 255, 255, 0.8)',
  },
} satisfies THEME_TOKENS
